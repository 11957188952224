export const queryKeys = {
  Activities: "activities",
  ActiveTask: "active-task",
  AddBuilderGroup: "add-builder-group",
  AddGallery: "add-gallery",
  AddImageToGallery: "add-image-to-gallery",
  AddProductOrService: "add-product-or-service",
  ApproveProject: "approve-project",
  AssignProjectToBuilder: "assign-project-to-builder",
  AssignTaskToBuilder: "assign-task-to-builder",
  AddMemberToBuilderGroup: "add-member-to-builder-group",
  Bids: "bids",
  SubContractors: "sub-contractors",
  BuildersGroups: "builders-groups",
  Categories: "categories",
  Clients: "clients",
  Companies: "companies",
  Countries: "countries",
  CreateInvoice: "create-invoice",
  ConvertEstimateToInvoice: "convert-estimate-to-invoice",
  CreateBranding: "create-branding",
  CreateBuilder: "create-builder",
  CreateCategory: "create-category",
  CreateClient: "create-client",
  CreateCompany: "create-company",
  CreateRole: "create-role",
  CreateTask: "create-task",
  Currencies: "currencies",
  DeleteBuilder: "delete-builder",
  DeleteCategory: "delete-category",
  DeleteEmployee: "delete-employee",
  DeleteEstimate: "delete-estimate",
  DeleteGallery: "delete-gallery",
  DeleteInvoice: "delete-invoice",
  DeleteNotification: "delete-notification",
  DeleteRole: "delete-role",
  DeleteSubmission: "delete-submission",
  DeleteProductOrService: "delete-product-or-service",
  Employees: "employees",
  EmployeesRoles: "employees-roles",
  Estimates: "estimates",
  ForgotPassword: "forgot-password",
  Galleries: "galleries",
  ImportImageToGallery: "import-image-to-gallery",
  Invoices: "invoices",
  NewPassword: "new-password",
  Notifications: "notifications",
  OpenBid: "open-bid",
  OTP: "otp",
  OutPayment: "out-payment",
  Permissions: "permissions",
  ProductsAndServices: "products-or-services",
  Projects: "projects",
  ProjectsForBuilder: "projects-for-builder",
  ProjectsForCompany: "projects-for-company",
  ReceivePayment: "receive-payment",
  RecordPayment: "record-payment",
  Roles: "roles",
  RolesForCompany: "roles-for-company",
  SendNotification: "send-notification",
  Submissions: "submissions",
  SubmitBidForTask: "submit-bid-for-task",
  Tasks: "tasks",
  TasksForCompany: "tasks-for-company",
  Transactions: "transactions",
  UpdateAppCode: "update-app-code",
  Users: "users",
  UserProfile: "user-profile",
  UpdateBrand: "update-brand",
  UpdateBuilder: "update-builder",
  UpdateCategory: "update-category",
  UpdateCompany: "update-company",
  UpdateInvoice: "update-invoice",
  UpdateProductOrService: "update-product-or-service",
  UpdateBuilderGroup: "update-builder-group",
  Website: "website",
  Company: "company",
  Builders: "builders",
  Prices: "prices",
  Subscribe: "subscribe",
};
