import { useQuery } from "@tanstack/react-query";
import { usePostMutation } from "./useQueryFns";
import { useUserSelector } from "./useUserSelector";

const usePostQuery = ({ url, queryKey = [], data = {}, enabled = true }) => {
  const { user } = useUserSelector();
  const { postMutation } = usePostMutation();
  return useQuery({
    queryKey: [...queryKey],
    queryFn: () =>
      postMutation({
        url,
        data: {
          // company_id: "64dab6840dfc8961c4a5525e",
          company_id: user.company._id,
          ...data,
        },
      }),
    enabled,
  });
};

export default usePostQuery;
