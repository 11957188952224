// import React from "react";
// import { Editor, Ruler, Toolbar, Sidebar } from "react-planner";

// function Notes() {
//   return (
//     <div style={{ display: "flex", height: "100vh" }}>
//       <Sidebar />
//       <div style={{ flex: 1 }}>
//         <Toolbar />
//         <div style={{ display: "flex", height: "calc(100% - 40px)" }}>
//           <div style={{ width: "40px" }}>
//             <Ruler orientation="vertical" />
//           </div>
//           <div style={{ flex: 1 }}>
//             <Editor width="100%" height="100%" />
//           </div>
//         </div>
//         <div style={{ width: "100%", height: "40px" }}>
//           <Ruler orientation="horizontal" />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Notes;


import React from 'react'

function Notes() {
  return (
    <div>Notes</div>
  )
}

export default Notes
