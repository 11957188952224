import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useUserSelector } from "./useUserSelector";

const baseURL = process.env.REACT_APP_BASE_URL_ADMIN;

export const useGetQuery = ({
  queryKey = [],
  url,
  options = {
    headers: {},
    params: {},
  },
  enable = true,
}) => {
  const { user } = useUserSelector();

  return useQuery({
    queryKey,
    queryFn: async () => {
      const res = await axios.get(`${baseURL}${url}`, {
        headers: {
          Authorization: `Bearer ${user?.auth_token}`,
          roleid: user?.role._id,
          ...options.headers,
        },
        params: {
          ...options.params,
        },
      });
      return res.data;
    },
    enabled: enable,
  });
};
