import React from "react";

function ChatPlaceholder() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="863.917"
      height="364.205"
      data-name="Layer 1"
      viewBox="0 0 863.917 364.205"
      className="w-96"
    >
      <path
        fill="#f0f0f0"
        d="M311.959 119.745L0 119.745 0 222.156 11.817 222.156 11.817 248.941 38.601 222.156 311.959 222.156 311.959 119.745z"
      ></path>
      <path fill="#fff" d="M8.666 129.718H303.294V211.647H8.666z"></path>
      <path fill="#f0f0f0" d="M34.721 154.426H176.577V158.731H34.721z"></path>
      <path
        fill="#f0f0f0"
        d="M34.721 169.318H281.964V173.62300000000002H34.721z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M34.721 184.21H281.722V188.51500000000001H34.721z"
      ></path>
      <path fill="#f0f0f0" d="M524.031 351.07s1.487-31.16 31.972-27.538"></path>
      <circle cx="515.418" cy="306.161" r="15.257" fill="#f0f0f0"></circle>
      <path fill="#f0f0f0" d="M512.935 331.853H517.242V362H512.935z"></path>
      <circle cx="666.93" cy="180.073" r="123.297" fill="#3f3d56"></circle>
      <path
        fill="#fff"
        d="M589.307 189.97a32.627 32.627 0 0150.08 0 36.264 36.264 0 10-51.27-1.189q.58.609 1.19 1.19zM687.219 189.97a32.627 32.627 0 0150.08 0 36.264 36.264 0 10-51.27-1.189q.58.609 1.19 1.19z"
      ></path>
      <circle cx="601.976" cy="151.392" r="12.474" fill="#3f3d56"></circle>
      <circle cx="699.885" cy="151.392" r="12.474" fill="#3f3d56"></circle>
      <circle cx="578.083" cy="210.898" r="14.505" fill="#6c63ff"></circle>
      <circle cx="744.896" cy="210.898" r="14.505" fill="#6c63ff"></circle>
      <path
        fill="#6c63ff"
        d="M661.49 181.886L650.611 229.029 668.742 210.898 661.49 181.886z"
      ></path>
      <path
        fill="#3f3d56"
        d="M717.39 363.205L705.038 352.839 705.326 363.205 701.49 363.205 701.183 352.244 684.507 363.205 677.526 363.205 701.059 347.737 700.147 315.258 699.466 290.728 703.293 290.623 703.984 315.258 704.894 347.708 723.354 363.205 717.39 363.205z"
      ></path>
      <path
        fill="#3f3d56"
        d="M659.363 363.205L647.012 352.839 647.3 363.205 643.474 363.205 643.167 352.244 626.49 363.205 619.509 363.205 643.033 347.737 642.122 315.258 641.441 290.728 645.276 290.623 645.967 315.258 646.868 347.708 665.328 363.205 659.363 363.205z"
      ></path>
      <path
        fill="#3f3d56"
        d="M668.743 47.71c-3.383 0-6.368 2.629-8.363 6.665-1.759-6.07-5.454-10.29-9.77-10.29a6.563 6.563 0 00-.87.146c-1.659-6.48-5.514-11.026-10.008-11.026-6.009 0-10.88 8.118-10.88 18.132s4.871 18.132 10.88 18.132a6.561 6.561 0 00.87-.146c1.66 6.48 5.514 11.025 10.009 11.025 3.383 0 6.367-2.628 8.363-6.664 1.759 6.07 5.453 10.29 9.769 10.29 6.008 0 10.879-8.117 10.879-18.131s-4.87-18.132-10.88-18.132zM550.682 183.91l-67.92-11.017c-3.423-.555-6.908-1.111-10.342-.628s-6.87 2.174-8.621 5.167a8.651 8.651 0 009.15 12.853c-3.708-.12-7.604-.2-10.894 1.514s-5.62 5.875-4.016 9.22a8.277 8.277 0 001.92 2.429 17.606 17.606 0 0018.522 3.14c-2.5 3.587-7.462 4.12-11.754 4.95s-9.253 3.258-9.413 7.626c-.179 4.909 5.663 7.518 10.472 8.517a137.417 137.417 0 0076.82-6.261 30.98 30.98 0 007.738-3.95 17.433 17.433 0 00-7.054-30.964M843.849 239.582a137.419 137.419 0 00-51.173-57.637 30.975 30.975 0 00-7.807-3.81 17.433 17.433 0 00-20.509 24.247l-5.315-2.75 32.014 60.907c1.613 3.07 3.263 6.19 5.713 8.643s5.866 4.185 9.31 3.786a8.6 8.6 0 006.78-12.3 16.643 16.643 0 005.751 5.06c3.347 1.6 8.074.96 9.783-2.332a8.275 8.275 0 00.788-2.994 17.606 17.606 0 00-8.621-16.692c4.368-.157 7.776 3.489 11.019 6.42 3.243 2.931 8.165 5.438 11.753 2.94 4.03-2.807 2.605-9.044.514-13.488z"
      ></path>
      <path
        fill="#cacaca"
        d="M55.757 0L506 0 506 147.807 488.945 147.807 488.945 186.463 450.289 147.807 55.757 147.807 55.757 0z"
      ></path>
      <path fill="#fff" d="M68.264 14.393H493.493V132.639H68.264z"></path>
      <path fill="#6c63ff" d="M102.459 48.916H307.196V55.129H102.459z"></path>
      <path
        fill="#6c63ff"
        d="M102.459 70.41H459.299V76.62299999999999H102.459z"
      ></path>
      <path fill="#6c63ff" d="M102.459 91.903H458.949V98.116H102.459z"></path>
      <path
        fill="#cacaca"
        d="M862.917 364.205h-381a1 1 0 010-2h381a1 1 0 010 2z"
      ></path>
    </svg>
  );
}

export default ChatPlaceholder;
